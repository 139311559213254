/****************MOBILE FLY-OUT****************/

var is_nav_locked = false;

$('#menu-trigger').bind('tap click', function(e) {

	e.preventDefault();

	if (is_nav_locked) {
		return;
	}

	$('body').toggleClass('menu-in');

	/* if ($('body').hasClass('menu-in')) {
		$('nav').scrollTop(0); // make sure the sidebar is scrolled all the way up
	} */

	is_nav_locked = true;

	setTimeout(function(){ is_nav_locked = false; }, 300);
});